<template>
  <div class="advocacy">
    <atom-heading type="h1" variant="md" class="advocacy__heading">
      Espace Plaidoyer
    </atom-heading>
    <p class="advocacy__paragraph">
      Espace de rédaction et diffusion des plaidoyers. Il s'agit des actions de plaidoyer digitalisées, élaborées directement par les communautés adhérentes et diffusées sur E-Tachawor. Les thématiques abordées seront liées aux finances publiques.
    </p>
    <form action="" class="advocacy__form">
      <div class="advocacy__form-group">
        <input 
          v-model="advocacy.subject" 
          type="text" 
          class="advocacy__form-item" 
          placeholder="Sujet" 
          @blur="v$.subject.$touch"
        />
        <div class="advocacy__form-errors">
          <span class="advocacy__form-error" v-for="(err, index) in v$.subject.$errors" :key="index">{{err.$message}}</span>
        </div>
      </div>
      <div class="advocacy__form-item-wrapper">
        <div class="advocacy__form-group">
          <textarea
            class="advocacy__form-item advocacy__form-item--textarea"
            placeholder="Écrivez votre lettre"
            v-model="advocacy.body"
            @blur="v$.body.$touch"
          ></textarea>
          <div class="advocacy__form-errors">
            <span class="advocacy__form-error" v-for="(err, index) in v$.body.$errors" :key="index">{{err.$message}}</span>
          </div>
        </div>
        <div class="advocacy__form-footer">
          <div class="advocacy__form-files">
            <div class="advocacy__form-file" v-for="(file, index) in Array.from(files)" :key="index">
              {{file.name}}
            </div>
          </div>
          <label class="advocacy__form-item-icons" for="file-upload__input">
            <input
              type="file"
              hidden
              id="file-upload__input"
              @change="updateSelectedFile"
            />
            <atom-icon
              class="advocacy__form-item-icons-item"
              icon="attachment"
            ></atom-icon>
          </label>
        </div>
      </div>
      <atom-button 
        :class="`advocacy__form-button ${!v$.$invalid ? 'advocacy__form-button--active' : ''}`"
        @click.prevent="createAdvocacy"
      >
        <span class="advocac__form-button-text">Envoyer</span>
        <atom-icon
          v-if="!loading"
          icon="send-plane-2"
          :fill="true"
          class="advocacy__form-button-icon"
        ></atom-icon>
        <svg v-else :class="`advocacy__form-button-icon ${loading ? 'advocacy__form-button-icon--active' : 'advocacy__form-button-icon--disabled'}`" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
      </atom-button>
    </form>
    <block-success-alert 
      :class="`advocacy__popup ${isPopupVisible ? 'advocacy__popup--active':''}`"
      :content="alertContent"
    />
  </div>
</template>

<script setup>
  import {computed, onBeforeMount, ref} from "vue";
  import { useVuelidate } from "@vuelidate/core";
  import {required, helpers} from "@vuelidate/validators";
  import {useStore} from "vuex";
  import {filesMutationTypes} from "@/store/types/mutations";
  import BlockSuccessAlert from "@/components/blocks/BlockSuccessAlert.vue";
  const {state, commit, dispatch} = useStore();
  const isPopupVisible = ref(false);
  const loading = computed( () => state.posts.loading);
  const advocacy = ref({
    subject:'',
    body:''
  });
  const alertContent = ref({
    title:'Plaidoyer envoyé',
    body:'Votre plaidoyer a été enovoyé avec succes, Nous vous remercions pour votre feedback.',
  });
  const rules = {
    subject:{required:helpers.withMessage('Le sujet est requis', required)},
    body:{required:helpers.withMessage('Le contenu est requis', required)}
  }
  const v$ = useVuelidate(rules, advocacy);
  // Get the files from store 
  const files = computed(() => state.files.files);

  const updateSelectedFile = (e) => {
    commit(`files/${filesMutationTypes.SET_FILES}`, e.target.files);
  }

  // Method to create advocacy 
  const createAdvocacy = async () => {
    commit('posts/SET_LOADING',true);
    // Upload files if any 
    if(files.value?.length){
      try {
        const [{id}] = await dispatch("files/upload", files.value);
        advocacy.value.file = id;        
      } catch (err) {
        console.log(err);
      }
    }
    try {
      const createdAdvocacy = await dispatch("advocacies/create", advocacy.value);
      // Empty form fields
      // Show success notification for one second
      isPopupVisible.value = true;
      commit('posts/SET_LOADING',false);
      setTimeout(function(){
        isPopupVisible.value = false;
      },3000);
    } catch (err) {
      console.log(err);
    }
  }

  onBeforeMount( () => {
    commit('posts/SET_LOADING',false);
    // Clear the store files 
    commit(`files/${filesMutationTypes.SET_FILES}`, []);
  });
</script>

<style lang="scss" scoped>
.advocacy {
  @apply container mx-auto px-4 mt-7 mb-13 relative;
  &__heading {
    @apply mb-3;
  }
  &__paragraph {
    @apply text-black/80 text-base mb-7;
  }
  &__form {
    &-group{
      @apply mb-4;
    }
    &-item {
      @apply w-full py-[13px] px-[10px]
        border border-[#D8D7D6]
        rounded-md
        bg-white;
      &--textarea {
        @apply h-[410px] resize-none;
      }
      &-wrapper {
        @apply relative;
      }
      &-icons{
        @apply ml-auto w-[80px] cursor-pointer flex gap-6 bg-[#D8D7D6] px-6 py-1 rounded-md;
        &-item {
          @apply w-4 fill-[#858585] cursor-pointer;
        }
      }
    }
    &-footer {
        @apply
        w-full px-4
        absolute bottom-9
        flex justify-between items-center;
    }
    &-file{
      @apply bg-[#D8D7D6] text-[#858585] px-6 py-1 rounded-md;
    }
    &-button {
      @apply pointer-events-none bg-[#D8D7D6] flex items-center gap-2 px-8;
      &-icon {
        @apply fill-[#fff] w-4;
        &--active{
          @apply animate-spin block;
        }
        &--disabled{
          @apply hidden;
        }
      }
      &-text {
        @apply font-medium;
      }
      &--active{
        @apply bg-primary pointer-events-auto;
      }
    }
    &-errors{
    @apply flex flex-col gap-1 mt-1 text-right;
    }
    &-error{
      @apply text-red-600 text-sm;
    }
  }
  &__popup{
    @apply  max-w-[300px] sm:max-w-[400px] lg:max-w-[700px] fixed right-10 lg:right-[unset] lg:left-1/2 lg:-translate-x-1/2 bottom-[-200px] opacity-0
    transition-all;
    &--active{
      @apply bottom-[20px] sm:bottom-[50px] lg:bottom-[80px] opacity-100;
    }
  }
}
</style>
